/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import cookie from "services/cookie.js";
import dayjs from "dayjs";

// Overview page components
import Header from "layouts/narioZona/components/Header";

function Overview() {
  const token = cookie.getCookie("token");
  const [narys, setNarys] = React.useState([]);
  const [viso, setViso] = React.useState([]);
  const [atlikta, setAtlikta] = React.useState(false);
  const [sestadienis, setSestadienis] = React.useState([]);
  const [sestadienisMokami, setSestadienisMokami] = React.useState([]);
  const timestamp = Date.now();

  React.useEffect(() => {
    fetch(`api/narys/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const narioDuomenys = data.results;
        setNarys(narioDuomenys);
      });
  }, []);

  React.useEffect(() => {
    fetch(`/api/patikrinti-sestadienis/50820241104/${narys[0]?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSestadienis(data.results);
      });
  }, [narys, atlikta]);

  React.useEffect(() => {
    fetch(`/api/sestadienis/50820241104/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setViso(data.results);
      });
  }, [narys, atlikta]);

  React.useEffect(() => {
    fetch(`/api/sestadienis-mokami/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let duomenys = {};

        for (let i = 0; i < data.results.length; i++) {
          if (!duomenys[data.results[i].grupe])
            duomenys[data.results[i].grupe] = [];
          duomenys[data.results[i].grupe].push(
            data.results[i].uzsakymo_id_funkcines
          );
        }

        setSestadienisMokami(duomenys);
      });
  }, [narys, atlikta]);

  const atliktiRegistracija = (narys) => {
    fetch(`/api/registruotis-sestadienis/50820241104/${narys}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setAtlikta(Math.random());
      });
  };

  const atsauktiRegistracija = (narys) => {
    fetch(`/api/atsaukti-sestadienis/50820241104/${narys}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setAtlikta(Math.random());
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={2} mb={3}>
          <Grid container spacing={1}>
            {!narys[0] ? (
              <div className="mt-3">
                <Alert severity="warning">
                  Aktyvios - apmokėtos studijos narystės šiuo metu neturite, dėl
                  to nario nuolaidos jums šiuo metu netaikomos
                </Alert>
              </div>
            ) : null}

            {narys?.map(function (item, i) {
              let apmoketaNaryste = true;
              if (
                dayjs(item.galioja_iki) < dayjs() ||
                item.sustabdziusi ||
                !item.aktyvus
              ) {
                apmoketaNaryste = false;
              }

              if (i >= 1) return <React.Fragment key={i}></React.Fragment>;

              if (!apmoketaNaryste) {
                return (
                  <Alert severity="warning">
                    Aktyvios - apmokėtos studijos narystės šiuo metu neturite,
                    dėl to nario nuolaidos jums šiuo metu netaikomos
                  </Alert>
                );
              }

              return (
                <React.Fragment key={i}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    sx={{ display: "flex" }}
                    key={i}
                  >
                    <h1>Kauno mieste:</h1>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    sx={{ display: "flex" }}
                    key={i}
                  >
                    <Typography fontWeight="regular" variant="h6" color="text">
                      Kiekvieną savaitgalį, studijos narius, kviečiame
                      prisijungti į papildomas treniruotes.
                      <br />Į treniruotę priimame iki 16 asmenų.
                      <br />
                      Registracija būtina norint dalyvauti.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    sx={{ display: "flex" }}
                    key={i}
                  >
                    <Typography
                      fontWeight="regular"
                      variant="h6"
                      color="text"
                      sx={{ boxShadow: "none", mt: "16px" }}
                    >
                      <strong>Ateinančio savaitgalio treniruotės:</strong>
                      <MDBox sx={{ overflow: "auto" }}>
                        <MDBox
                          sx={{
                            width: "100%",
                            display: "table",
                            tableLayout: "fixed",
                          }}
                        >
                          <TableContainer
                            sx={{ boxShadow: "none", mt: "16px" }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow
                                  sx={{ borderTop: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Gruodžio 14d. (ŠEŠTADIENIS): 09:00 - 09:50
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Tempimo - mobilumo treniruotė
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Nemokama nariams
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    {viso[0] && viso.length > 15 ? (
                                      <MDButton
                                        color="secondary"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Visos vietos užimtos
                                      </MDButton>
                                    ) : narys[0] && !sestadienis[0] ? (
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                        onClick={() =>
                                          atliktiRegistracija(narys[0].id)
                                        }
                                      >
                                        Registruotis nemokamai
                                      </MDButton>
                                    ) : null}

                                    {narys[0] && sestadienis[0] ? (
                                      <MDButton
                                        color="warning"
                                        variant="gradient"
                                        type="submit"
                                        onClick={() =>
                                          atsauktiRegistracija(narys[0].id)
                                        }
                                      >
                                        Atšaukti registraciją
                                      </MDButton>
                                    ) : null}
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta {viso.length} iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>

                                <TableRow>
                                  <DataTableBodyCell>
                                    Gruodžio 14d. (ŠEŠTADIENIS): 10:20 - 11:20
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ JULIJA: Full body workout.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 10 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/sestadieniu-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Gruodzio14`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Gruodzio14"]?.length
                                      ? sestadienisMokami["Gruodzio14"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Gruodžio 15d. (SEKMADIENIS): 10:00 - 11:00
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ LUKRECIJA: Joga
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 2 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/joga-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Gruodzio15`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Gruodzio15"]?.length
                                      ? sestadienisMokami["Gruodzio15"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </MDBox>
                      </MDBox>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    sx={{ display: "flex" }}
                    key={i}
                  >
                    <Typography
                      fontWeight="regular"
                      variant="h6"
                      color="text"
                      sx={{ boxShadow: "none", mt: "32px" }}
                    >
                      <strong>Tolimesni savaitgaliai:</strong>

                      <MDBox sx={{ overflow: "auto" }}>
                        <MDBox
                          sx={{
                            width: "100%",
                            display: "table",
                            tableLayout: "fixed",
                          }}
                        >
                          <TableContainer
                            sx={{ boxShadow: "none", mt: "16px" }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow
                                  sx={{ borderTop: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Gruodžio 21d. (ŠEŠTADIENIS): 09:00 - 09:50
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Tempimo - mobilumo treniruotė
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Nemokama nariams
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <MDButton
                                      color="success"
                                      variant="gradient"
                                      type="submit"
                                      disabled="true"
                                    >
                                      Registracija dar neatidaryta
                                    </MDButton>
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Gruodžio 21d. (ŠEŠTADIENIS): 10:20 - 11:20
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ INDRĖ: Piloxing
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 10 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/sestadieniu-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Gruodzio21`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Gruodzio21"]?.length
                                      ? sestadienisMokami["Gruodzio21"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Gruodžio 22d. (SEKMADIENIS): 10:00 - 11:00
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ LUKRECIJA: Joga
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 2 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/joga-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Gruodzio22`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Gruodzio22"]?.length
                                      ? sestadienisMokami["Gruodzio22"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>

                                <TableRow
                                  sx={{ borderTop: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Gruodžio 28d. (ŠEŠTADIENIS): 09:00 - 09:50
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Tempimo - mobilumo treniruotė
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Nemokama nariams
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <MDButton
                                      color="success"
                                      variant="gradient"
                                      type="submit"
                                      disabled="true"
                                    >
                                      Registracija dar neatidaryta
                                    </MDButton>
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Gruodžio 28d. (ŠEŠTADIENIS): 10:20 - 11:20
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Šventinė treniruotė.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 10 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/sestadieniu-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Gruodzio28`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Gruodzio28"]?.length
                                      ? sestadienisMokami["Gruodzio28"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Gruodžio 29d. (SEKMADIENIS): 10:00 - 11:00
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ LUKRECIJA: Joga
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 2 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/joga-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Gruodzio29`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Gruodzio29"]?.length
                                      ? sestadienisMokami["Gruodzio29"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>

                                <TableRow
                                  sx={{ borderTop: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 4 d. (ŠEŠTADIENIS): 09:00 - 09:50
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Tempimo - mobilumo treniruotė
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Nemokama nariams
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <MDButton
                                      color="success"
                                      variant="gradient"
                                      type="submit"
                                      disabled="true"
                                    >
                                      Registracija dar neatidaryta
                                    </MDButton>
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Sausio 4 d. (ŠEŠTADIENIS): 10:20 - 11:20
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERIS GABRIELIUS: Pakabinami diržai -
                                    medball, savimasažas.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 10 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/sestadieniu-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Sausio4`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Sausio4"]?.length
                                      ? sestadienisMokami["Sausio4"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow
                                  sx={{ borderBottom: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 5 d. (SEKMADIENIS): 10:00 - 11:00
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ LUKRECIJA: Joga
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 2 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/joga-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Sausio5`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Sausio5"]?.length
                                      ? sestadienisMokami["Sausio5"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>

                                <TableRow
                                  sx={{ borderTop: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 11 d. (ŠEŠTADIENIS): 09:00 - 09:50
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Tempimo - mobilumo treniruotė
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Nemokama nariams
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <MDButton
                                      color="success"
                                      variant="gradient"
                                      type="submit"
                                      disabled="true"
                                    >
                                      Registracija dar neatidaryta
                                    </MDButton>
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Sausio 11 d. (ŠEŠTADIENIS): 10:20 - 11:20
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERIS EDGARAS: Bag boxing.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 10 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/sestadieniu-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Sausio11`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Sausio11"]?.length
                                      ? sestadienisMokami["Sausio11"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow
                                  sx={{ borderBottom: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 12 d. (SEKMADIENIS): 10:00 - 11:00
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ LUKRECIJA: Joga
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 2 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/joga-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Sausio12`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Sausio12"]?.length
                                      ? sestadienisMokami["Sausio12"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>

                                <TableRow
                                  sx={{ borderTop: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 18 d. (ŠEŠTADIENIS): 09:00 - 09:50
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Tempimo - mobilumo treniruotė
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Nemokama nariams
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <MDButton
                                      color="success"
                                      variant="gradient"
                                      type="submit"
                                      disabled="true"
                                    >
                                      Registracija dar neatidaryta
                                    </MDButton>
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow>
                                  <DataTableBodyCell>
                                    Sausio 18 d. (ŠEŠTADIENIS): 10:20 - 11:20
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ INRĖ: Piloxing.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 10 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/sestadieniu-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Sausio18`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Sausio18"]?.length
                                      ? sestadienisMokami["Sausio18"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow
                                  sx={{ borderBottom: "2px solid #dedede;" }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 19 d. (SEKMADIENIS): 10:00 - 11:00
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    TRENERĖ LUKRECIJA: Joga
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Kaina nariams - 2 EUR.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://www.balticpower.co.uk/parduotuve/joga-nariams/?id=${item.id}&timestamp=${timestamp}${item.id}&vardas=${item.asmuo}&pastas=${item.pastas}&treniruote=Sausio19`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Registracija
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta{" "}
                                    {sestadienisMokami["Sausio19"]?.length
                                      ? sestadienisMokami["Sausio19"]?.length
                                      : 0}{" "}
                                    iš 16 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    borderBottom: "2px solid #dedede;",
                                    borderTop: "2px solid #dedede;",
                                  }}
                                >
                                  <DataTableBodyCell>
                                    Sausio 26 d. (SEKMADIENIS): 10:00 iki 14:30
                                    val.
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    BALTICPOWER TRENIRUOČIŲ DIENA
                                  </DataTableBodyCell>
                                  <DataTableBodyCell></DataTableBodyCell>
                                  <DataTableBodyCell>
                                    <a
                                      href={`https://tickets.paysera.com/lt/event/naujametine-balticpower-treniruociu-diena-keturios-treniruotes-per-viena-diena`}
                                    >
                                      <MDButton
                                        color="success"
                                        variant="gradient"
                                        type="submit"
                                      >
                                        Daugiau informacijos
                                      </MDButton>
                                    </a>
                                  </DataTableBodyCell>
                                  <DataTableBodyCell>
                                    Užimta 56 iš 120 vietų.
                                  </DataTableBodyCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </MDBox>
                      </MDBox>
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
